<template>
  <div>
    <Navbar />
    <div class="columns">
      <SideBar class="menu" />
      <section class="container">
        <Header class="header" />
        <div class="card-sup">
          <vs-col>
            <div class="card-info">
              <h3>
                <font-awesome-icon
                  style="margin-right: 3.5px"
                  :icon="['fas', 'list']"
                />
                Acompanhamento
              </h3>
              <vs-divider />
              <vs-row class="pd-table">
                <vs-col>
                  <fieldset>
                    <legend>Em Andamento</legend>
                    <div v-if="protocolInProgress != ''">
                      <vs-table
                        class="table_intranet"
                        hoverFlat
                        :data="protocolInProgress"
                      >
                        <template slot="thead">
                          <vs-th width="31.5%"> PROTOCOLO </vs-th>
                          <vs-th> SOLICITAÇÃO </vs-th>
                        </template>

                        <template slot-scope="{ data }">
                          <vs-tr
                            :key="index"
                            v-for="(i, index) in data"
                            class="protocol"
                          >
                            <vs-td>
                              <b>{{ i.CODATENDIMENTO }}</b>
                              <br />
                              <span>
                                <b style="font-size: 0.975em">
                                  <font-awesome-icon
                                    :icon="['fas', 'calendar-day']"
                                  />
                                </b>
                                {{ i.ABERTURA }}
                              </span>
                              <br /><br />
                              <span>
                                  <font-awesome-icon
                                  style="margin-right: 3.5px; color: green;"
                                    :icon="['fas', 'check-double']"
                                  />
                                <b style="font-size: 0.95em">{{ i.ETAPA.toUpperCase() }}</b>
                              </span>
                            </vs-td>

                            <vs-td>
                              <p
                                v-html="
                                  i.SOLICITACAO.replaceAll('\n', '<br />')
                                "
                              ></p>
                            </vs-td>
                          </vs-tr>
                        </template>
                      </vs-table>
                    </div>
                    <div v-else>
                      <p>Nenhum protocolo em andamento</p>
                    </div>
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row class="pd-table">
                <vs-col>
                  <fieldset>
                    <legend>Concluído(s)</legend>
                    <div v-if="protocolCompleted != ''">
                      <vs-table
                        class="table_intranet"
                        max-items="10"
                        hoverFlat
                        pagination
                        :data="protocolCompleted"
                      >
                        <template slot="thead">
                          <vs-th width="31.5%"> PROTOCOLO </vs-th>
                          <vs-th> SOLUÇÃO </vs-th>
                          <vs-th width="6.15%"> ANEXO </vs-th>
                        </template>

                        <template slot-scope="{ data }">
                          <vs-tr
                            :key="index"
                            v-for="(i, index) in data"
                            class="protocol"
                          >
                            <vs-td>
                              <b>{{ i.CODATENDIMENTO }}</b>
                              <br />
                              <span>
                                <b style="font-size: 0.975em">
                                  <font-awesome-icon
                                    :icon="['fas', 'calendar-day']"
                                  />
                                </b>
                                {{ i.ABERTURA }}
                              </span>
                              <br /><br />
                              <span>
                                  <font-awesome-icon
                                  style="margin-right: 3.5px; color: green;"
                                    :icon="['fas', 'check-double']"
                                  />
                                <b style="font-size: 0.95em">{{ i.ETAPA.toUpperCase() }}</b>
                              </span>
                            </vs-td>

                            <vs-td>
                              <p
                                v-html="
                                  i.TEXTOSOLUCAO.replaceAll('\n', '<br />')
                                "
                              ></p>
                            </vs-td>

                            <vs-td>
                              <div v-if="i.ANEXO == 'S'">
                                <vs-button
                                  class="button-table"
                                  @click="openDocument(i)"
                                >
                                  <font-awesome-icon
                                    color="white"
                                    :icon="['fas', 'download']"
                                  />
                                </vs-button>
                              </div>
                              <div v-else>-</div>
                            </vs-td>
                          </vs-tr>
                        </template>
                      </vs-table>
                    </div>
                    <div v-else>
                      <p>Nenhum protocolo concluído</p>
                    </div>
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-popup
                style="z-index: 999999 !important"
                :title="'Protocolo: ' + codProtocol"
                :active.sync="popupInformation"
              >
                <div v-if="processSelected">
                  <fieldset>
                    <p>{{ processSelected.TEXTOSOLUCAO }}</p>
                  </fieldset>
                </div>
              </vs-popup>

              <vs-popup
                title="Anexo"
                :active.sync="showDownloadModal"
              >
                <vs-row>
                  <div class="sucess sucess-info" style="width: 100%">
                    <h3>
                      <font-awesome-icon
                        color="#139B0E"
                        style="margin-right: 2.5px"
                        :icon="['fas', 'check-circle']"
                      />
                      Anexo gerado com sucesso
                    </h3>
                    <br />
                    <p style="font-size: 1.185em; color: black">
                      Prezado aluno, seu anexo foi gerado com sucesso,<br /><br />
                      <a style="color: black" :href="urlDocument" target="_blank">
                        Clique aqui
                      </a>
                      para abrir o documento.
                      <br /><br />
                      QI Faculdade & Escola Técnica<br />O mercado reconhece, valoriza e contrata quem faz QI!
                    </p>
                  </div>
                </vs-row>
              </vs-popup>
            </div>
          </vs-col>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Management from "../../services/management";

export default {
  name: "Acompanhamento",
  data: () => ({
    protocolInProgress: [],
    protocolCompleted: [],
    showDownloadModal: false,
    popupInformation: false,
    processSelected: null,
    codProtocol: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Procurando Atendimento ⌚" });
    new Management()
      .getProtocolInProgress()
      .then(({ data }) => {
        this.protocolInProgress = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });

    this.$vs.loading({ text: "Procurando Atendimento ⌚" });
    new Management()
      .getProtocolCompleted()
      .then(({ data }) => {
        this.protocolCompleted = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    openDocument(tr) {
      this.showDownloadModal = false;

      this.$vs.loading({ text: "Gerando Anexo 📩" });
      new Management()
        .getAttachmentAttendance(tr.CODCOLIGADA, tr.CODLOCAL, tr.CODATENDIMENTO)
        .then(({ data }) => {
          this.attachmentAttendance = Array.isArray(data) ? data : [data];
          this.openBase64InNewTab(this.attachmentAttendance[0].BASE64STRING);
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    openBase64InNewTab(base64Data) {
      let mimeType = "";
      const base64PrefixPattern = /^data:([^;]+);base64,/;

      const match = base64Data.match(base64PrefixPattern);
      if (match) {
        mimeType = match[1];
        base64Data = base64Data.replace(base64PrefixPattern, "");
      } else {
        mimeType = this.getMimeTypeFromBinary(base64Data) || "application/octet-stream";
      }

      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });
      this.urlDocument = URL.createObjectURL(blob);
      this.showDownloadModal = true;
    },

    getMimeTypeFromBinary(base64Data) {
      const firstBytes = atob(base64Data).slice(0, 4).split("").map(c => c.charCodeAt(0));
      
      const mimeTypes = {
        "25504446": "application/pdf", // PDF
        "89504E47": "image/png",       // PNG
        "47494638": "image/gif",       // GIF
        "FFD8FFDB": "image/jpeg",      // JPEG
        "FFD8FFE0": "image/jpeg",      // JPEG
        "FFD8FFE1": "image/jpeg"       // JPEG
      };

      const hexSignature = firstBytes.map(byte => byte.toString(16).padStart(2, "0")).join("").toUpperCase();

      return mimeTypes[hexSignature] || null;
    }
  },
};
</script>

<style scoped>
tr:nth-child(even) {
  background-color: #d4d4d4;
}

legend {
  font-size: 1.05em;
}

.card-sup {
  margin-left: 8.5px;
  margin-right: 8.5px;
  margin-bottom: 8.5px;
}

@media screen and (max-width: 450px) {
  p {
    font-size: 0.85em;
  }

  .protocol {
    font-size: 0.85em;
  }
}
</style>