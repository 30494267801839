<template>
  <div>
    <Navbar />
    <div class="columns">
      <SideBar class="menu" />
      <section class="container">
        <Header class="header" />
        <div class="card-sup">
          <vs-col>
            <div class="card-info">
              <h3>
                <font-awesome-icon
                  style="margin-right: 3.5px"
                  :icon="['fas', 'window-maximize']"
                />
                Atividade Complementar
              </h3>
              <vs-divider />
              <vs-row class="pd-t">
                <vs-col>
                  <div class="alert alert-info">
                    <h3>
                      <font-awesome-icon
                        style="margin-right: 1.5px"
                        :icon="['fas', 'radiation']"
                      />
                      ATENÇÃO
                    </h3>
                    <p style="margin-top: 5px">
                      Somente alunos com matrícula ativa na <b>Graduação</b>, podem emitir as
                      atividades complementares.
                    </p>
                  </div>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col>
                  <div v-if="courses[0]">
                    <fieldset>
                      <legend>Curso <b style="color: red">*</b></legend>
                      <ValidationProvider name="Curso" rules="required">
                        <div>
                          <vs-select
                            class="selectExample"
                            placeholder="Selecione um Curso"
                            v-model="courseSelected"
                            @change="getObject()"
                          >
                            <vs-select-item
                              :key="index"
                              :value="item"
                              :text="'(' + item.STATUS + ') - ' + item.NOME"
                              v-for="(item, index) in courses"
                            />
                          </vs-select>
                        </div>
                      </ValidationProvider>
                    </fieldset>
                    <div v-if="courseSelected" style="margin-top: 10px; margin-right: 3.5px; display: flex; justify-content: flex-end;">
                      <a href="https://qi.edu.br/wp-content/uploads/2024/12/FAQI_REGULAMENTO_ATIVIDADES_COMPLEMENTARES.pdf" target="_blank">
                        <font-awesome-icon style="margin-right: 1.5px" :icon="['fas', 'download']" />
                        Baixar Regulamento
                      </a>
                    </div>
                  </div>
                  <div v-else>
                    <div class="error-info">
                      <b>
                        <font-awesome-icon
                          style="color: red; margin-right: 3.5px"
                          :icon="['fas', 'exclamation-triangle']"
                        />
                        Nenhum curso de graduação encontrado
                      </b>
                    </div>
                  </div>
                </vs-col>
              </vs-row>

              <vs-row class="pd-table" v-if="activityComplementary != null">
                <vs-divider />
                <div v-if="activityComplementary == ''" style="width: 100%">
                  <div class="error-info">
                    <b>
                      <font-awesome-icon
                        style="color: red; margin-right: 3.5px"
                        :icon="['fas', 'exclamation-triangle']"
                      />
                      O curso selecionado não possui atividade complementar
                    </b>
                  </div>
                </div>

                <vs-col v-else>
                  <div style="text-align: center">
                    <vs-col class="card-ativity" vs-w="4" vs-sm="6" vs-xs="12">
                      <fieldset>
                        <p class="title-ativity">Carga Horária Aprovada</p>
                        <b class="circle-aprove">{{activityComplementary[0].CHTOTALALUNO}}</b>
                      </fieldset>
                    </vs-col>
                    <vs-col class="card-ativity" vs-w="4" vs-sm="6" vs-xs="12">
                      <fieldset>
                        <p class="title-ativity">
                          Carga Horária Curricular
                        </p>
                        <b class="circle">{{activityComplementary[0].CHTOTAL}}</b>
                      </fieldset>
                    </vs-col>
                    <vs-col vs-w="4" vs-sm="12" vs-xs="12">
                      <fieldset>
                        <p class="title-ativity">Carga Horária Faltante</p>
                        <b class="circle-reprove">{{activityComplementary[0].CHTOTALFALTANTEALUNO}}</b>
                      </fieldset>
                    </vs-col>
                  </div>
                  <div>
                    <vs-row>
                      <vs-col style="margin-top: 25px !important">
                        <vs-table
                          :data="activityComplementary"
                          hoverFlat
                          search
                          max-items="10"
                          pagination
                        >
                          <template slot="thead">
                            <vs-th width="10%"><p style="width: 100%; text-align: center;">SITUAÇÃO</p></vs-th>
                            <vs-th>DESCRIÇÃO</vs-th>
                            <vs-th width="23.5%"><p style="width: 100%; text-align: center;">CH. TOTAL DO COMPONENTE</p></vs-th>
                            <vs-th width="15%"><p style="width: 100%; text-align: center;">CH. APROVADA</p></vs-th>
                          </template>

                          <template slot-scope="{ data }">
                            <vs-tr v-for="(tr, index) in data" :key="index">
                              <vs-td style="text-align: center">
                                <font-awesome-icon
                                  v-if="tr.CHCURSADA >= tr.CHCOMPONENTE"
                                  size="2x"
                                  style="color: green"
                                  :icon="['fas', 'check-circle']"
                                />
                                <font-awesome-icon
                                  v-else
                                  size="2x"
                                  style="color: red"
                                  :icon="['fas', 'times-circle']"
                                />
                              </vs-td>
                              <vs-td>
                                {{ tr.COMPONENTE }}
                              </vs-td>
                              <vs-td style="text-align: center">
                                {{ tr.CHCOMPONENTE }}
                              </vs-td>
                              <vs-td style="text-align: center">
                                <b>{{ tr.CHCURSADA }}</b>
                              </vs-td>
                            </vs-tr>
                          </template>
                        </vs-table>
                      </vs-col>
                    </vs-row>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import Courses from "../../services/management";
import Person from "../../services/person";
import Student from "../../services/students";

export default {
  name: "AtividadeComplementar",
  data: () => ({
    courses: [],
    personalData: [],
    ra: null,
    activityComplementary: null,
    courseSelected: null,
    error: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Procurando Cursos 📚" });
    new Courses()
      .getCoursesForCoefficient()
      .then(({ data }) => {
        this.courses = Array.isArray(data) ? data : [data];
        this.$vs.loading.close();
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
        this.$vs.loading.close();
      });

    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];

        this.ra = this.personalData[0].RA;
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });
  },

  methods: {
    getObject() {
      this.$vs.loading({ text: "Buscando Atividades 💯" });
      new Student()
        .getComplementaryActivity(
          this.courseSelected.CODCOLIGADA,
          this.courseSelected.IDHABILITACAOFILIAL,
          this.ra
        )
        .then(({ data }) => {
          this.activityComplementary = Array.isArray(data) ? data : [data];
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
  },
};
</script>

<style scoped>
tr:nth-child(even) {
  background-color: #d4d4d4;
}

legend {
  font-size: 1.05em;
}

.card-sup {
  margin-left: 8.5px;
  margin-right: 8.5px;
  margin-bottom: 8.5px;
}

.title-ativity {
  font-weight: bold;
  margin-bottom: 12.5px !important;
}

.circle-aprove,
.circle,
.circle-reprove {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
}

.circle-aprove {
  background-color: #09b300;
  border: 3.15px solid #00ff15;
}

.circle {
  background-color: #000000;
  border: 3.15px solid #4c4c4c;
}

.circle-reprove {
  background-color: #ff0000;
  border: 3.15px solid #b30000;
}

@media (max-width: 1125px) {
  .title-ativity {
    font-size: 0.785em;
  }

  .circle-aprove,
  .circle,
  .circle-reprove {
    width: 55px;
    height: 55px;
    font-size: 0.785em;
  }
}

@media (max-width: 900px) {
  .title-ativity {
    font-size: 0.85em;
  }

  .circle-aprove,
  .circle,
  .circle-reprove {
    width: 60px;
    height: 60px;
    font-size: 0.85em;
  }
}

@media (max-width: 840px) {
  .title-ativity {
    font-size: 1em;
  }

  .circle-aprove,
  .circle,
  .circle-reprove {
    font-size: 1em;
  }
}

@media (max-width: 600px) {
  .card-ativity {
    margin-bottom: 15px;
  }
}
</style>