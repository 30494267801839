<template>
  <div>
    <vs-divider />
    <div class="card-sup">
      <vs-col>
        <div class="card-info">
          <h3>
            <font-awesome-icon
              style="margin-right: 3.5px"
              :icon="['fas', 'user-times']"
            />
            Justificativa de Ausência
          </h3>
          <vs-divider />
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <div class="con-select-example">
              <vs-row>
                <vs-col>
                  <Message
                    :msg="idAttendance"
                    :error="errorIdAttendance"
                    :errorDuplicate="idAttendanceDuplicate"
                    v-if="
                      idAttendance || errorIdAttendance || idAttendanceDuplicate
                    "
                  />
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend style="font-size: 0.95em">Email do Aluno</legend>
                    <vs-input
                      class="selectExampleDouble"
                      :placeholder="email"
                      disabled
                    />
                  </fieldset>
                </vs-col>

                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend style="font-size: 0.95em">Telefone</legend>
                    <vs-input
                      class="selectExampleDouble"
                      :placeholder="telephone"
                      disabled
                    />
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend style="font-size: 0.95em">Disciplina <b style="color: red">*</b></legend>
                    <ValidationProvider name="Disciplina" rules="required">
                      <vs-select
                        class="selectExample"
                        v-model="disciplineSelected"
                        placeholder="Selecione a Disciplina"
                      >
                        <vs-select-item
                          :key="item.POLO"
                          :value="item.DISCIPLINA"
                          :text="item.DISCIPLINA"
                          v-for="item in disciplines"
                        />
                      </vs-select>
                    </ValidationProvider>
                  </fieldset>
                </vs-col>

                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend style="font-size: 0.95em">Período Faltante <b style="color: red">*</b></legend>
                    <ValidationProvider name="Período Faltante" rules="required">
                      <vs-select
                        class="selectExample"
                        v-model="periodSelected"
                        placeholder="Selecione o Período"
                      >
                        <vs-select-item
                          :key="item.key"
                          :value="item.description"
                          :text="item.description"
                          v-for="item in period"
                        />
                      </vs-select>
                    </ValidationProvider>
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col>
                  <fieldset>
                    <legend style="font-size: 0.95em">
                      Atestado <b style="color: red">*</b>
                    </legend>
                    <ValidationProvider
                      name="Atestado"
                      ref="provider"
                      rules="size: 5000"
                    >
                      <input
                        class="selectExample"
                        ref="certificate"
                        type="file"
                        @change="onFileUploadCertificate"
                      />
                    </ValidationProvider>
                    <legend
                      style="
                        margin-top: 5px;
                        margin-left: 2.15px;
                        font-size: 0.65em;
                      "
                    >
                      <font-awesome-icon
                        style="margin-right: 1.5px"
                        :icon="['fas', 'sd-card']"
                      />
                      Tamanho Máximo: 5MB
                    </legend>
                    <div
                      v-if="errorAttachment"
                      style="margin-top: 10px"
                      class="error-info"
                    >
                      <b>
                        <font-awesome-icon
                          style="color: red; margin-right: 3.5px"
                          :icon="['fas', 'exclamation-triangle']"
                        />
                        O Atestado deve ser menor de 5MB
                      </b>
                    </div>
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col>
                  <ValidationProvider
                    name="Observação"
                    rules="required|max: 500"
                  >
                    <vs-textarea
                      rows="11"
                      label="Observação *"
                      class="textArea"
                      height="15em"
                      counter="500"
                      v-model="solicitation"
                    />
                  </ValidationProvider>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col style="text-align: center">
                  <vs-button
                    color="danger"
                    type="gradient"
                    class="button-protocol"
                    @click="resetForm()"
                    :disabled="!disciplineSelected && !periodSelected && !solicitation && !visibleButtonCertificate"
                  >
                    <font-awesome-icon
                      style="margin-right: 3.5px"
                      :icon="['fas', 'times-circle']"
                    />
                    Cancelar
                  </vs-button>

                  <vs-button
                    class="button-protocol"
                    color="success"
                    type="gradient"
                    @click="createAttendance()"
                    :disabled="
                      invalid ||
                      !visibleButtonCertificate ||
                      idAttendanceDuplicate
                    "
                  >
                    <font-awesome-icon
                      style="margin-right: 3.5px"
                      :icon="['fas', 'check-circle']"
                    />
                    Gerar
                  </vs-button>
                </vs-col>
              </vs-row>
            </div>
          </ValidationObserver>
        </div>
      </vs-col>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import Person from "../../../services/person";
import Management from "../../../services/management";
import ProtocolModel from "../../../models/menus/protocols/protocolTypes";
import Attendance from "../../../services/attendance";

export default {
  name: "JustificativaAusencia",
  props: {
    course: Object,
    filial: String,
  },
  data: () => ({
    errorAttachment: false,
    filesCertificate: null,
    base64Certificate: null,
    visibleButtonCertificate: false,
    disciplineSelected: null,
    periodSelected: null,
    disciplines: [],
    period: [
      { description: "UNIDADE 1", key: 1 },
      { description: "UNIDADE 2", key: 2 },
      { description: "UNIDADE 3", key: 3 },
      { description: "UNIDADE 4", key: 4 },
      { description: "UNIDADE 5", key: 5 },
      { description: "UNIDADE 6", key: 6 },
      { description: "PROVA", key: 7 },
      { description: "SUBSTITUIÇÃO", key: 8 },
    ],
    email: null,
    telephone: null,
    solicitation: null,
    idAttendance: null,
    errorIdAttendance: null,
    idAttendanceDuplicate: null,
  }),

  mounted() {
    this.$vs.loading({ text: "Carregando Dados Acadêmicos 🪪" });
    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];

        this.nome = this.personalData[0].NOME;
        this.ra = this.personalData[0].RA;
        this.email =
          this.personalData[0].EMAIL == null ? "-" : this.personalData[0].EMAIL;
        this.emailFinancialResponsible =
          this.personalData[0].EMAILRESPONSAVELFINANCEIRO == null
            ? "-"
            : this.personalData[0].EMAILRESPONSAVELFINANCEIRO;
        this.telephone =
          this.personalData[0].TELEFONE1 == null
            ? "-"
            : `(${this.personalData[0].TELEFONE1.slice(
                0,
                2
              )}) ${this.personalData[0].TELEFONE1.slice(2, 12)}`;
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });

    new Management()
      .getProtocolInProgress()
      .then(({ data }) => {
        this.protocolInProgress = (Array.isArray(data) ? data : [data]).filter(
          (a) =>
            a.ASSUNTOOC == "JUSTIFICATIVA DE AUSÊNCIA - PORTAL DO ALUNO" &&
            a.CURSO == `(${this.course.CODCURSO}) ${this.course.CURSO}`
        );

        if (this.protocolInProgress.length != 0) {
          this.idAttendanceDuplicate =
            this.protocolInProgress[0].CODATENDIMENTO;
          this.$vs.loading.close();
        }
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });

    this.$vs.loading({ text: "Carregando Dados Acadêmicos 🪪" });
    new Management()
      .getDisciplines(this.course.CODCURSO)
      .then(({ data }) => {
        this.disciplines = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    async onFileUploadCertificate(event) {
      this.errorAttachment = false;

      this.filesCertificate = event.target.files[0];
      const { valid } = await this.$refs.provider.validate(
        this.filesCertificate
      );

      if (valid) {
        this.createCertificate(this.filesCertificate);
        this.visibleButtonCertificate = true;
      } else {
        this.errorAttachment = true;
      }
    },

    createCertificate(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;
        this.base64Certificate = window.btoa(bynaryData);
      };
      reader.readAsBinaryString(file);
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      this.idAttendanceDuplicate = null;

      const studentName = this.nome;
      const studentRA = this.ra;

      /* INFORMAÇÕES EXTERNAS */
      const courseSolicitacion = this.course.CURSO;
      const filialSolicitacion = this.filial;

      const payload = new ProtocolModel().getDataByAttendanceId("P31");
      payload.data.locationId = this.course.POLO;
      payload.data.ra = studentRA;
      payload.data.attachment = this.base64Certificate;
      payload.data.fileName = this.filesCertificate.name;
      payload.data.nameCourse = `(${this.course.CODCURSO}) ${this.course.CURSO}`;
      payload.data.requestText = `RA: ${studentRA}\nALUNO: ${studentName}\nE-MAIL DO ALUNO: ${this.email}\nE-MAIL DO RESPONSÁVEL FINANCEIRO: ${this.emailFinancialResponsible}\nTELEFONE: ${this.telephone}\nCURSO: ${courseSolicitacion}\nESCOLA: ${filialSolicitacion}\n\nDISCPLINA: ${this.disciplineSelected}\nPERÍODO FALTANTE: ${this.periodSelected}\nOBSERVAÇÃO: ${this.solicitation}`;

      this.$vs.loading({ text: "Gerando Atendimento 🏷️" });
      new Management()
        .getProtocolInProgress()
        .then(({ data }) => {
          this.protocolInProgress = (
            Array.isArray(data) ? data : [data]
          ).filter(
            (a) =>
              a.ASSUNTOOC == payload.data.subject &&
              a.CURSO == payload.data.nameCourse
          );

          if (this.protocolInProgress.length != 0) {
            this.idAttendanceDuplicate =
              this.protocolInProgress[0].CODATENDIMENTO;
            this.$vs.loading.close();
          } else {
            new Attendance()
              .createAttendance(payload.data)
              .then(({ data }) => {
                this.idAttendance = data;
                this.$vs.loading.close();
              })
              .catch(({ response }) => {
                if (response && response.data) {
                  this.errorIdAttendance = response.data;
                  console.log(response);
                }
                this.$vs.loading.close();
              });
          }
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
          }
        });

      this.resetForm();
    },

    resetForm() {
      this.errorAttachment = false;
      this.disciplineSelected = null;
      this.periodSelected = null;
      this.base64Certificate = null;
      this.filesCertificate = null;
      this.visibleButtonCertificate = false;

      this.$refs.certificate.value = null;
      this.solicitation = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  },
};
</script>

<style scoped>
.card-sup {
  margin: 20px 8.5px 8.5px 8.5px;
}
</style>