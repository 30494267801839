<template>
  <div>
    <vs-divider />
    <div class="card-sup">
      <vs-col>
        <div class="card-info">
          <h3>
            <font-awesome-icon
              style="margin-right: 3.5px"
              :icon="['fas', 'check-circle']"
            />
            Certificado
          </h3>
          <vs-divider />
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <div class="con-select-example">
              <vs-row>
                <vs-col>
                  <Message2
                    :msg="idAttendance"
                    :error="errorIdAttendance"
                    :errorDuplicate="idAttendanceDuplicate"
                    v-if="
                      idAttendance || errorIdAttendance || idAttendanceDuplicate
                    "
                  />
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend>Email do Aluno</legend>
                    <vs-input
                      class="selectExampleDouble"
                      :placeholder="email"
                      disabled
                    />
                  </fieldset>
                </vs-col>

                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend>Telefone</legend>
                    <vs-input
                      class="selectExampleDouble"
                      :placeholder="telephone"
                      disabled
                    />
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col>
                  <fieldset>
                    <legend>Disciplinas Aprovadas</legend>
                    <div class="discipline discipline-info">
                      <vs-table :data="subjects" hoverFlat>
                        <template slot-scope="{ data }">
                          <vs-tr v-for="(tr, index) in data" :key="index">
                            <vs-td
                              style="
                                padding: 5px;
                                border: none !important;
                                background-color: #e8f6fc;
                              "
                            >
                              <font-awesome-icon
                                v-if="data[index].DISCIPLINA == null"
                                style="color: red; margin-right: 3.5px"
                                :icon="['fas', 'times']"
                              />
                              <font-awesome-icon
                                v-else
                                style="color: green; margin-right: 3.5px"
                                :icon="['fas', 'check-circle']"
                              />
                              <b>
                                {{
                                  data[index].DISCIPLINA == null
                                    ? "Nenhuma disciplina aprovada"
                                    : data[index].DISCIPLINA
                                }}
                              </b>
                            </vs-td>
                          </vs-tr>
                        </template>
                      </vs-table>
                    </div>
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col>
                  <fieldset class="document-info">
                    <b>Documentos Solicitados</b>
                    <div style="padding: 10px 5px">
                      <span>
                        <div v-if="invisibleRG">
                          <div
                            v-if="visibleButtonCNH && visibleButtonCertNasCas"
                          >
                            <b>
                              <font-awesome-icon
                                style="
                                  color: green;
                                  margin-left: -3.5px;
                                  margin-right: 5px;
                                "
                                :icon="['fas', 'check-circle']"
                              />
                              CNH (Carteira Nacional de Habilitação)
                            </b>
                            <br />
                          </div>
                          <li v-else>CNH (Carteira Nacional de Habilitação)</li>

                          <div v-if="visibleButtonCertNasCas">
                            <b>
                              <font-awesome-icon
                                style="
                                  color: green;
                                  margin-left: -3.5px;
                                  margin-right: 5px;
                                "
                                :icon="['fas', 'check-circle']"
                              />
                              Certidão de Nascimento/Casamento
                            </b>
                            <br />
                          </div>
                          <li v-else>Certidão de Nascimento/Casamento</li>
                        </div>
                        <div v-else>
                          <div
                            v-if="visibleButtonRGFrente && visibleButtonRGVerso"
                          >
                            <b>
                              <font-awesome-icon
                                style="
                                  color: green;
                                  margin-left: -3.5px;
                                  margin-right: 5px;
                                "
                                :icon="['fas', 'check-circle']"
                              />
                              RG (Registro Geral) - Frente e Verso
                            </b>
                            <br />
                          </div>
                          <li v-else>RG (Registro Geral) - Frente e Verso</li>
                        </div>
                      </span>
                      <span>
                        <div v-if="visibleButtonCPF">
                          <b>
                            <font-awesome-icon
                              style="
                                color: green;
                                margin-left: -3.5px;
                                margin-right: 5px;
                              "
                              :icon="['fas', 'check-circle']"
                            />
                            CPF (Cadastro de Pessoa Física)
                          </b>
                          <br />
                        </div>
                        <li v-else>CPF (Cadastro de Pessoa Física)</li>
                      </span>
                      <span>
                        <div v-if="visibleButtonCompResid">
                          <b>
                            <font-awesome-icon
                              style="
                                color: green;
                                margin-left: -3.5px;
                                margin-right: 5px;
                              "
                              :icon="['fas', 'check-circle']"
                            />
                            Comprovante de Residência
                          </b>
                          <br />
                        </div>
                        <li v-else>Comprovante de Residência</li>
                      </span>
                    </div>

                    <p>
                      *Caso não possua RG, aceita-se a
                      <b>CNH (Carteira Nacional de Habilitação)</b> juntamente
                      com a <b>certidão de nascimento</b> ou
                      <b>casamento</b> como alternativa.
                    </p>

                    <p style="margin-top: 10px; font-weight: bold">
                      <font-awesome-icon
                        style="margin-right: 1.5px"
                        :icon="['fas', 'exclamation-circle']"
                      />
                      Assegure-se de que a digitalização dos documentos esteja
                      legível e em um formato adequado para envio e sem cortes
                      nas bordas.
                    </p>
                    <vs-divider />
                    <fieldset style="display: flex">
                      <input
                        type="checkbox"
                        style="margin-right: 3.5px; margin-bottom: 0.35px"
                        v-model="invisibleRG"
                        @change="rgInvisible"
                      />Não possuo RG
                    </fieldset>
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row v-if="invisibleRG" class="pd-t">
                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend class="title-input">
                      CNH (Carteira Nacional de Habilitação)
                      <b style="color: red">*</b>
                    </legend>
                    <ValidationProvider
                      name="CNH (Carteira Nacional de Habilitação) "
                      ref="provider"
                      rules="size: 5000"
                    >
                      <input
                        class="selectExample"
                        ref="cnh"
                        type="file"
                        accept="image/*"
                        @change="onFileUploadCNH"
                      />
                    </ValidationProvider>
                    <legend
                      style="
                        margin-top: 5px;
                        margin-left: 2.15px;
                        font-size: 0.65em;
                      "
                    >
                      <font-awesome-icon
                        style="margin-right: 1.5px"
                        :icon="['fas', 'sd-card']"
                      />
                      Tamanho Máximo: 5MB
                    </legend>
                    <div v-if="errorCNH" style="margin-top: 10px" class="error-info">
                      <b>
                        <font-awesome-icon
                          style="color: red; margin-right: 3.5px"
                          :icon="['fas', 'exclamation-triangle']"
                        />
                        A CNH deve ser menor de 5MB
                      </b>
                    </div>
                  </fieldset>
                </vs-col>

                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend class="title-input">
                      Cert. de Nascimento/Casamento <b style="color: red">*</b>
                    </legend>
                    <ValidationProvider
                      name="Cert. de Nascimento/Casamento"
                      ref="provider"
                      rules="size: 5000"
                    >
                      <input
                        class="selectExample"
                        ref="certNasccas"
                        type="file"
                        accept="image/*"
                        @change="onFileUploadCertNasCas"
                      />
                    </ValidationProvider>
                    <legend
                      style="
                        margin-top: 5px;
                        margin-left: 2.15px;
                        font-size: 0.65em;
                      "
                    >
                      <font-awesome-icon
                        style="margin-right: 1.5px"
                        :icon="['fas', 'sd-card']"
                      />
                      Tamanho Máximo: 5MB
                    </legend>
                    <div v-if="errorCertNascCas" style="margin-top: 10px" class="error-info">
                      <b>
                        <font-awesome-icon
                          style="color: red; margin-right: 3.5px"
                          :icon="['fas', 'exclamation-triangle']"
                        />
                        O Cert. de Nascimento/Casamento deve ser menor de 5MB
                      </b>
                    </div>
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row v-else class="pd-t">
                <fieldset style="width: 100%">
                  <legend class="title-input">
                    RG (Registro Geral) <b style="color: red">*</b>
                  </legend>
                  <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                    <legend style="font-size: 0.825em; margin-left: 2.15px">
                      <font-awesome-icon :icon="['fas', 'address-card']" />
                      FRENTE
                    </legend>
                    <ValidationProvider
                      name="RGFrente"
                      ref="provider"
                      rules="size: 5000"
                    >
                      <input
                        class="selectExample"
                        style="
                          border: 1px solid #999;
                          border-radius: 5px;
                          padding: 6.5px;
                        "
                        ref="rgFrente"
                        type="file"
                        accept="image/*"
                        @change="onFileUploadRGFrente"
                      />
                    </ValidationProvider>
                    <legend
                      style="
                        margin-top: 5px;
                        margin-left: 2.15px;
                        font-size: 0.65em;
                      "
                    >
                      <font-awesome-icon
                        style="margin-right: 1.5px"
                        :icon="['fas', 'sd-card']"
                      />
                      Tamanho Máximo: 5MB
                    </legend>
                    <div v-if="errorRGFrente" style="margin-top: 10px" class="error-info">
                      <b>
                        <font-awesome-icon
                          style="color: red; margin-right: 3.5px"
                          :icon="['fas', 'exclamation-triangle']"
                        />
                        O RG (Frente) deve ser menor de 5MB
                      </b>
                    </div>
                  </vs-col>
                  <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                    <legend style="font-size: 0.825em; margin-left: 2.15px">
                      <font-awesome-icon :icon="['fas', 'credit-card']" />
                      VERSO
                    </legend>
                    <ValidationProvider
                      name="RGVerso"
                      ref="provider"
                      rules="size: 5000"
                    >
                      <input
                        class="selectExample"
                        style="
                          border: 1px solid #999;
                          border-radius: 5px;
                          padding: 6.5px;
                        "
                        ref="rgVerso"
                        type="file"
                        accept="image/*"
                        @change="onFileUploadRGVerso"
                      />
                    </ValidationProvider>
                    <legend
                      style="
                        margin-top: 5px;
                        margin-left: 2.15px;
                        font-size: 0.65em;
                      "
                    >
                      <font-awesome-icon
                        style="margin-right: 1.5px"
                        :icon="['fas', 'sd-card']"
                      />
                      Tamanho Máximo: 5MB
                    </legend>
                    <div v-if="errorRGVerso" style="margin-top: 10px" class="error-info">
                      <b>
                        <font-awesome-icon
                          style="color: red; margin-right: 3.5px"
                          :icon="['fas', 'exclamation-triangle']"
                        />
                        O RG (Verso) deve ser menor de 5MB
                      </b>
                    </div>
                  </vs-col>
                </fieldset>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend class="title-input">
                      CPF (Cadastro de Pessoa Física)
                      <b style="color: red">*</b>
                    </legend>
                    <ValidationProvider
                      name="CPF (Cadastro de Pessoa Física)"
                      ref="provider"
                      rules="size: 5000"
                    >
                      <input
                        class="selectExample"
                        ref="cpf"
                        type="file"
                        @change="onFileUploadCPF"
                      />
                    </ValidationProvider>
                    <legend
                      style="
                        margin-top: 5px;
                        margin-left: 2.15px;
                        font-size: 0.65em;
                      "
                    >
                      <font-awesome-icon
                        style="margin-right: 1.5px"
                        :icon="['fas', 'sd-card']"
                      />
                      Tamanho Máximo: 5MB
                    </legend>
                    <div v-if="errorCPF" style="margin-top: 10px" class="error-info">
                      <b>
                        <font-awesome-icon
                          style="color: red; margin-right: 3.5px"
                          :icon="['fas', 'exclamation-triangle']"
                        />
                        O CPF deve ser menor de 5MB
                      </b>
                    </div>
                  </fieldset>
                </vs-col>

                <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                  <fieldset>
                    <legend class="title-input">
                      Comp. de Residência <b style="color: red">*</b>
                    </legend>
                    <ValidationProvider
                      name="Comp. de Residência"
                      ref="provider"
                      rules="size: 5000"
                    >
                      <input
                        class="selectExample"
                        ref="compResid"
                        type="file"
                        @change="onFileUploadCompResid"
                      />
                    </ValidationProvider>
                    <legend
                      style="
                        margin-top: 5px;
                        margin-left: 2.15px;
                        font-size: 0.65em;
                      "
                    >
                      <font-awesome-icon
                        style="margin-right: 1.5px"
                        :icon="['fas', 'sd-card']"
                      />
                      Tamanho Máximo: 5MB
                    </legend>
                    <div v-if="errorCompResid" style="margin-top: 10px" class="error-info">
                      <b>
                        <font-awesome-icon
                          style="color: red; margin-right: 3.5px"
                          :icon="['fas', 'exclamation-triangle']"
                        />
                        O Comp. de Residência deve ser menor de 5MB
                      </b>
                    </div>
                  </fieldset>
                </vs-col>
              </vs-row>

              <vs-row class="pd-t">
                <vs-col style="text-align: center">
                  <vs-button
                    color="danger"
                    type="gradient"
                    class="button-protocol"
                    @click="resetForm()"
                    :disabled="
                      !invisibleRG &&
                      (invisibleRG
                        ? !visibleButtonCNH && !visibleButtonCertNasCas
                        : !visibleButtonRGFrente && !visibleButtonRGVerso) &&
                      !visibleButtonCPF &&
                      !visibleButtonCompResid
                    "
                  >
                    <font-awesome-icon
                      style="margin-right: 3.5px"
                      :icon="['fas', 'times-circle']"
                    />
                    Cancelar
                  </vs-button>

                  <vs-button
                    v-if="subjects == '' || idAttendanceDuplicate"
                    class="button-protocol"
                    color="success"
                    type="gradient"
                    disabled
                  >
                    <font-awesome-icon
                      style="margin-right: 3.5px"
                      :icon="['fas', 'check-circle']"
                    />
                    Gerar
                  </vs-button>

                  <vs-button
                    v-else
                    class="button-protocol"
                    color="success"
                    type="gradient"
                    @click="createAttendance()"
                    :disabled="
                      invalid ||
                      idAttendanceDuplicate ||
                      (invisibleRG
                        ? !visibleButtonCNH || !visibleButtonCertNasCas
                        : !visibleButtonRGFrente || !visibleButtonRGVerso) ||
                      !visibleButtonCPF ||
                      !visibleButtonCompResid
                    "
                  >
                    <font-awesome-icon
                      style="margin-right: 3.5px"
                      :icon="['fas', 'check-circle']"
                    />
                    Gerar
                  </vs-button>
                </vs-col>
              </vs-row>
            </div>
          </ValidationObserver>
        </div>
      </vs-col>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import Person from "../../../services/person";
import Management from "../../../services/management";
import Student from "../../../services/students";
import ProtocolModel from "../../../models/menus/protocols/protocolTypes";
import Attendance from "../../../services/attendance";

export default {
  name: "Certificado",
  props: {
    course: Object,
    filial: String,
  },
  data: () => ({
    errorRGFrente: false,
    errorRGVerso: false,
    errorCNH: false,
    errorCertNascCas: false,
    errorCPF: false,
    errorCompResid: false,
    idAttendance: null,
    errorIdAttendance: null,
    idAttendanceDuplicate: null,
    filesRGFrente: null,
    base64RGFrente: null,
    filesRGVerso: null,
    base64RGVerso: null,
    filesCNH: null,
    base64CNH: null,
    filesCertNasCas: null,
    base64CertNasCas: null,
    filesCPF: null,
    base64CPF: null,
    filesCompResid: null,
    base64CompResid: null,
    invisibleRG: false,
    visibleButtonRGFrente: false,
    visibleButtonRGVerso: false,
    visibleButtonCNH: false,
    visibleButtonCertNasCas: false,
    visibleButtonCPF: false,
    visibleButtonCompResid: false,
    email: null,
    telephone: null,
    subjects: [],
    protocolInProgress: [],
  }),

  mounted() {
    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];

        this.nome = this.personalData[0].NOME;
        this.ra = this.personalData[0].RA;
        this.email =
          this.personalData[0].EMAIL == null ? "-" : this.personalData[0].EMAIL;
        this.emailFinancialResponsible =
          this.personalData[0].EMAILRESPONSAVELFINANCEIRO == null
            ? "-"
            : this.personalData[0].EMAILRESPONSAVELFINANCEIRO;
        this.telephone =
          this.personalData[0].TELEFONE1 == null
            ? "-"
            : `(${this.personalData[0].TELEFONE1.slice(
                0,
                2
              )}) ${this.personalData[0].TELEFONE1.slice(2, 12)}`;
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      });

    new Management()
      .getProtocolInProgress()
      .then(({ data }) => {
        this.protocolInProgress = (Array.isArray(data) ? data : [data]).filter(
          (a) =>
            a.ASSUNTOOC == "CERTIFICADO - PORTAL DO ALUNO" &&
            a.CURSO == `(${this.course.CODCURSO}) ${this.course.CURSO}`
        );

        if (this.protocolInProgress.length != 0) {
          this.idAttendanceDuplicate =
            this.protocolInProgress[0].CODATENDIMENTO;
          this.$vs.loading.close();
        }
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });

    this.$vs.loading({ text: "Carregando Dados Acadêmicos 🪪" });
    new Student()
      .getApprovedMatter(this.course.CODCURSO)
      .then(({ data }) => {
        this.subjects = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    rgInvisible() {
      this.visibleButtonRGFrente = false;
      this.visibleButtonRGVerso = false;
      this.visibleButtonCNH = false;
      this.visibleButtonCertNasCas = false;
      this.errorRGFrente = false;
      this.errorRGVerso = false;
      this.errorCNH = false;
      this.errorCertNascCas = false;
    },

    async onFileUploadRGFrente(event) {
      this.errorRGFrente = false;

      this.filesRGFrente = event.target.files[0];
      const { valid } = await this.$refs.provider.validate(this.filesRGFrente);

      if (valid) {
        this.createRGFrente(this.filesRGFrente);
        this.visibleButtonRGFrente = true;
      } else {
        this.errorRGFrente = true;
      }
    },

    async onFileUploadRGVerso(event) {
      this.errorRGVerso = false;

      this.filesRGVerso = event.target.files[0];
      const { valid } = await this.$refs.provider.validate(this.filesRGVerso);

      if (valid) {
        this.createRGVerso(this.filesRGVerso);
        this.visibleButtonRGVerso = true;
      } else {
        this.errorRGVerso = true;
      }
    },

    async onFileUploadCPF(event) {
      this.errorCPF = false;

      this.filesCPF = event.target.files[0];
      const { valid } = await this.$refs.provider.validate(this.filesCPF);

      if (valid) {
        this.createCPF(this.filesCPF);
        this.visibleButtonCPF = true;
      } else {
        this.errorCPF = true;
      }
    },

    async onFileUploadCNH(event) {
      this.errorCNH = false;

      this.filesCNH = event.target.files[0];
      const { valid } = await this.$refs.provider.validate(this.filesCNH);

      if (valid) {
        this.createCNH(this.filesCNH);
        this.visibleButtonCNH = true;
      } else {
        this.errorCNH = true;
      }
    },

    async onFileUploadCertNasCas(event) {
      this.errorCertNascCas = false;

      this.filesCertNasCas = event.target.files[0];
      const { valid } = await this.$refs.provider.validate(
        this.filesCertNasCas
      );

      if (valid) {
        this.createCertNasCas(this.filesCertNasCas);
        this.visibleButtonCertNasCas = true;
      } else {
        this.errorCertNascCas = true;
      }
    },

    async onFileUploadCompResid(event) {
      this.errorCompResid = false;

      this.filesCompResid = event.target.files[0];
      const { valid } = await this.$refs.provider.validate(this.filesCompResid);

      if (valid) {
        this.createCompResid(this.filesCompResid);
        this.visibleButtonCompResid = true;
      } else {
        this.errorCompResid = true;
      }
    },

    createRGFrente(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;
        this.base64RGFrente = window.btoa(bynaryData);
      };
      reader.readAsBinaryString(file);
    },

    createRGVerso(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;
        this.base64RGVerso = window.btoa(bynaryData);
      };
      reader.readAsBinaryString(file);
    },

    createCNH(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;
        this.base64CNH = window.btoa(bynaryData);
      };
      reader.readAsBinaryString(file);
    },

    createCertNasCas(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;
        this.base64CertNasCas = window.btoa(bynaryData);
      };
      reader.readAsBinaryString(file);
    },

    createCPF(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;
        this.base64CPF = window.btoa(bynaryData);
      };
      reader.readAsBinaryString(file);
    },

    createCompResid(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        var bynaryData = event.target.result;
        this.base64CompResid = window.btoa(bynaryData);
      };
      reader.readAsBinaryString(file);
    },

    async createAttendance() {
      this.idAttendance = null;
      this.errorIdAttendance = null;
      this.idAttendanceDuplicate = null;

      const studentName = this.nome;
      const studentRA = this.ra;

      /* INFORMAÇÕES EXTERNAS */
      const courseCertificado = this.course.CURSO;
      const filialCertificado = this.filial;

      const payload = new ProtocolModel().getDataByAttendanceId("P1");
      payload.data.locationId = this.course.POLO;
      payload.data.ra = studentRA;
      if (this.base64RGFrente) {
        payload.data.attachmentRGFrente = this.base64RGFrente;
        payload.data.fileNameRGFrente = this.filesRGFrente.name;
        payload.data.attachmentRGVerso = this.base64RGVerso;
        payload.data.fileNameRGVerso = this.filesRGVerso.name;
      } else {
        payload.data.attachmentCNH = this.base64CNH;
        payload.data.fileNameCNH = this.filesCNH.name;
        payload.data.attachmentCertNasc = this.base64CertNasCas;
        payload.data.fileNameCertNasc = this.filesCertNasCas.name;
      }
      payload.data.attachmentCPF = this.base64CPF;
      payload.data.fileNameCPF = this.filesCPF.name;
      payload.data.attachmentCompResid = this.base64CompResid;
      payload.data.fileNameCompResid = this.filesCompResid.name;
      payload.data.nameCourse = `(${this.course.CODCURSO}) ${this.course.CURSO}`;
      payload.data.requestText = `RA: ${studentRA}\nALUNO: ${studentName}\nE-MAIL DO ALUNO: ${this.email}\nE-MAIL DO RESPONSÁVEL FINANCEIRO: ${this.emailFinancialResponsible}\nTELEFONE: ${this.telephone}\nCURSO: ${courseCertificado}\nESCOLA: ${filialCertificado}`;

      this.$vs.loading({ text: "Gerando Atendimento 🏷️" });
      new Management()
        .getProtocolInProgress()
        .then(({ data }) => {
          this.protocolInProgress = (
            Array.isArray(data) ? data : [data]
          ).filter(
            (a) =>
              a.ASSUNTOOC == payload.data.subject &&
              a.CURSO == payload.data.nameCourse
          );

          if (this.protocolInProgress.length != 0) {
            this.idAttendanceDuplicate =
              this.protocolInProgress[0].CODATENDIMENTO;
            this.$vs.loading.close();
          } else {
            new Attendance()
              .createAttendance(payload.data)
              .then(({ data }) => {
                this.idAttendance = data;
                this.$vs.loading.close();
              })
              .catch(({ response }) => {
                if (response && response.data) {
                  this.errorIdAttendance = response.data;
                  console.log(response);
                }
                this.$vs.loading.close();
              });
          }
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
          }
        });

      this.resetForm();
    },

    formatAtt(atendimento) {
      let attendanceNumber = atendimento.split(";");
      return attendanceNumber[2];
    },

    resetForm() {
      this.errorRGFrente = false;
      this.errorRGVerso = false;
      this.errorCNH = false;
      this.errorCertNascCas = false;
      this.errorCPF = false;
      this.errorCompResid = false;

      this.base64RGFrente = null;
      this.filesRGFrente = null;
      this.base64RGVerso = null;
      this.filesRGVerso = null;
      this.base64CNH = null;
      this.filesCNH = null;
      this.base64CertNasCas = null;
      this.filesCertNasCas = null;
      this.base64CPF = null;
      this.filesCPF = null;
      this.base64CompResid = null;
      this.filesCompResid = null;
      this.base64HistoricEnsMed = null;
      this.filesHistoricEnsMed = null;
      this.base64Certificado = null;
      this.filesCertificado = null;
      this.visibleButtonRGFrente = false;
      this.visibleButtonRGVerso = false;
      this.visibleButtonCNH = false;
      this.visibleButtonCertNasCas = false;
      this.visibleButtonCPF = false;
      this.visibleButtonCompResid = false;

      if (this.invisibleRG) {
        this.$refs.cnh.value = null;
        this.$refs.certNasccas.value = null;
      } else {
        this.$refs.rgFrente.value = null;
        this.$refs.rgVerso.value = null;
      }

      this.invisibleRG = false;

      this.$refs.cpf.value = null;
      this.$refs.compResid.value = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  },
};
</script>

<style scoped>
.card-sup {
  margin: 20px 8.5px 8.5px 8.5px;
}

.document-info {
  font-size: 0.915em;
}

.title-input {
  font-size: 0.905em;
}

legend {
  font-size: 1.05em;
}

@media (max-width: 1000px) {
  .document-info {
    font-size: 0.85em;
    text-align: justify;
  }

  .title-input {
    font-size: 0.75em;
  }
}

@media (max-width: 900px) {
  .title-input {
    font-size: 0.95em;
  }
}

@media (max-width: 595px) {
  .document-info {
    font-size: 0.75em;
  }
}

@media (max-width: 420px) {
  .document-info {
    font-size: 0.7em;
  }

  .title-input {
    font-size: 0.75em;
  }
}
</style>