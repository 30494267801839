<template>
  <div class="qi-login">
    <div class="qi-form">
      <div class="logo">
        <img src="../assets/logo-qi.png" alt="Logo QI" />
      </div>

      <vs-row class="form-login" v-if="validatePassword == false">
        <b>Usuário</b>
        <vs-tooltip text="Digite seu CPF (apenas os números)" position="right">
          <font-awesome-icon
            style="color: #9b0e1c"
            :icon="['fas', 'question-circle']"
          />
        </vs-tooltip>
        <div style="margin-left: -73.5px; margin-top: 20px; margin-bottom: 5px">
          <p style="font-size: 0.715em">Digite seu CPF (apenas os números)</p>
        </div>
        <vs-col class="login-input" style="margin-bottom: 10px">
          <span class="icon-user">
            <font-awesome-icon :icon="['fas', 'user']" />
          </span>
          <vs-input
            class="user"
            v-model="user"
            placeholder="Digite seu usuário"
            @keyup.enter="focusPasswordInput"
          />
        </vs-col>

        <b>Senha</b>
        <vs-tooltip
          text="1º acesso: Data de Nascimento (ddmmaaaa)"
          position="right"
        >
          <font-awesome-icon
            style="color: #9b0e1c"
            :icon="['fas', 'question-circle']"
          />
        </vs-tooltip>
        <div style="margin-left: -65px; margin-top: 20px; margin-bottom: 5px">
          <p style="font-size: 0.715em">
            1º acesso: Data de Nascimento (ddmmaaaa)
          </p>
        </div>
        <vs-col class="login-input">
          <span class="icon-password">
            <font-awesome-icon
              v-if="passwordType == 'password'"
              :icon="['fas', 'lock']"
            />
            <font-awesome-icon v-else :icon="['fas', 'unlock-alt']" />
          </span>
          <vs-input
            class="password"
            v-model="password"
            :type="passwordType"
            placeholder="Digite sua senha"
            ref="password"
            @keyup.enter="login"
          />

          <a
            v-if="passwordType == 'password'"
            class="show-password"
            @click="showPassword()"
          >
            <font-awesome-icon :icon="['fas', 'eye']" />
          </a>

          <a v-else class="show-password" @click="showPassword()">
            <font-awesome-icon :icon="['fas', 'eye-slash']" />
          </a>
        </vs-col>

        <div
          v-if="error"
          style="
            color: black;
            margin-top: 10px;
            margin-bottom: 0 !important;
            width: 100%;
            text-align: center;
          "
          class="error-info"
        >
          <b class="error-title">
            <font-awesome-icon
              style="color: red; margin-right: 1.5px"
              :icon="['fas', 'times-circle']"
            />
            {{ error }}
          </b>
        </div>

        <div style="width: 100%">
          <vs-button
            class="button-login"
            @click="login()"
            :disabled="!user || !password"
          >
            <font-awesome-icon
              style="margin-right: 3.5px"
              :icon="['fas', 'sign-in-alt']"
            />
            Entrar
          </vs-button>

          <div class="information_login">
            <a @click="forgotPassword()">
              <font-awesome-icon
                style="margin-right: 1.5px"
                :icon="['fas', 'question-circle']"
              />
              Esqueceu sua Senha?
            </a>
          </div>
        </div>
      </vs-row>

      <vs-row v-if="validatePassword == true && changePassword == false">
        <div
          style="color: black; width: 100%; text-align: center"
          class="discipline discipline-info"
        >
          <b class="error-title">
            <font-awesome-icon
              style="margin-right: 1.5px"
              :icon="['fas', 'exclamation-triangle']"
            />
            ATENÇÃO
          </b>
          <p class="error-alert">
            Um email foi enviado com um token de autenticação.
          </p>
          <p
            class="error-alert"
            style="
              border: 1px solid;
              border-radius: 5px;
              padding: 6.15px;
              margin-bottom: 8.5px;
            "
          >
            <font-awesome-icon
              style="margin-right: 1.5px"
              :icon="['fas', 'at']"
            />
            Email: <b>{{ maskEmail(this.infoEmail) }}</b>
          </p>
          <div style="display: flex; justify-content: space-around">
            <a
              style="
                color: black;
                border: 2.15px solid;
                border-radius: 7.5px;
                padding: 6.15px;
              "
              :style="isButtonToken ? 'color: gray' : 'color: black'"
              @click="isButtonToken ? '' : resendEmail()"
            >
              <font-awesome-icon
                style="margin-right: 3.5px"
                :icon="['fas', 'sync']"
              />
              {{ isButtonToken ? formatTime() : "Reenviar Token" }}
            </a>
            <a
              style="
                color: black;
                border: 2.15px solid;
                border-radius: 7.5px;
                padding: 6.15px;
              "
              href="https://api.whatsapp.com/send?phone=5551995413830&text=Olá, gostaria de troca meu email para acesso ao portal. %23TrocarSenhaPortal"
              target="_blank"
            >
              <font-awesome-icon
                style="margin-right: 1.5px"
                :icon="['fas', 'reply-all']"
              />
              Trocar Email
            </a>
          </div>
        </div>

        <div
          v-if="newToken"
          style="
            color: black;
            margin-top: 10px;
            margin-bottom: 0 !important;
            width: 100%;
            text-align: center;
          "
          class="sucess sucess-info"
        >
          <b class="error-title">
            <font-awesome-icon
              style="color: green; margin-right: 1.5px"
              :icon="['fas', 'key']"
            />
            TOKEN GERADO!
          </b>
        </div>

        <vs-divider />

        <b>Token de Autenticação</b>
        <vs-tooltip text="Token de Autenticação (6 dígitos)" position="right">
          <font-awesome-icon
            style="color: #9b0e1c"
            :icon="['fas', 'question-circle']"
          />
        </vs-tooltip>
        <vs-col class="login-input">
          <span class="icon-password">
            <font-awesome-icon
              v-if="temporaryToken == 'password'"
              :icon="['fas', 'lock']"
            />
            <font-awesome-icon v-else :icon="['fas', 'unlock-alt']" />
          </span>
          <vs-input
            class="password"
            v-model="temporaryPassword"
            :type="temporaryToken"
            placeholder="Digite o token de autenticação"
          />

          <a
            v-if="temporaryToken == 'password'"
            class="show-password"
            @click="showToken()"
          >
            <font-awesome-icon :icon="['fas', 'eye']" />
          </a>

          <a v-else class="show-password" @click="showToken()">
            <font-awesome-icon :icon="['fas', 'eye-slash']" />
          </a>
        </vs-col>

        <div
          v-if="errorTemporary"
          style="
            color: black;
            margin-top: 10px;
            margin-bottom: 0 !important;
            width: 100%;
            text-align: center;
          "
          class="error-info"
        >
          <b class="error-title">
            <font-awesome-icon
              style="color: red; margin-right: 1.5px"
              :icon="['fas', 'times-circle']"
            />
            {{ errorTemporary }}
          </b>
          <p style="margin-top: 5px; font-size: 0.785em">
            Verifique se não há um token mais recente no seu email.
          </p>
        </div>

        <div
          v-if="successValidate"
          style="
            color: black;
            margin-top: 10px;
            margin-bottom: 0 !important;
            width: 100%;
            text-align: center;
          "
          class="sucess sucess-info"
        >
          <b class="error-title">
            <font-awesome-icon
              style="color: green; margin-right: 1.5px"
              :icon="['fas', 'check-circle']"
            />
            Token Validado!
          </b>
        </div>

        <div style="width: 100%">
          <vs-button
            class="button-login"
            :disabled="!temporaryPassword || temporaryPassword.length != 6"
            @click="validateToken(temporaryPassword)"
          >
            <font-awesome-icon
              style="margin-right: 3.5px"
              :icon="['fas', 'check-circle']"
            />
            Validar
          </vs-button>
        </div>
      </vs-row>

      <vs-row v-if="changePassword == true">
        <div
          v-if="passwordNew"
          style="
            color: black;
            margin-bottom: 0 !important;
            width: 100%;
            text-align: center;
          "
          class="sucess sucess-info"
        >
          <b class="error-title">
            <font-awesome-icon
              style="color: green; margin-right: 1.5px"
              :icon="['fas', 'check-circle']"
            />
            Senha Alterada!
          </b>
        </div>

        <div
          v-else
          style="color: black; width: 100%; text-align: center"
          class="error-info"
        >
          <b class="error-title">
            <font-awesome-icon
              style="color: red; margin-right: 1.5px"
              :icon="['fas', 'exclamation-triangle']"
            />
            Senha Expirada!
          </b>
          <p class="error-alert">Troque sua senha por uma outra diferente.</p>
        </div>

        <vs-divider />

        <b>Nova Senha</b>
        <vs-tooltip text="Digite sua nova senha" position="right">
          <font-awesome-icon
            style="color: #9b0e1c"
            :icon="['fas', 'question-circle']"
          />
        </vs-tooltip>
        <vs-col class="login-input" style="margin-bottom: 10px">
          <span class="icon-password">
            <font-awesome-icon
              v-if="newPasswordType == 'password'"
              :icon="['fas', 'lock']"
            />
            <font-awesome-icon v-else :icon="['fas', 'unlock-alt']" />
          </span>
          <vs-input
            class="password"
            v-model="newPassword"
            :type="newPasswordType"
            placeholder="Digite sua nova senha"
          />

          <a
            v-if="newPasswordType == 'password'"
            class="show-password"
            @click="showNewPassword()"
          >
            <font-awesome-icon :icon="['fas', 'eye']" />
          </a>

          <a v-else class="show-password" @click="showNewPassword()">
            <font-awesome-icon :icon="['fas', 'eye-slash']" />
          </a>
        </vs-col>

        <b>Confirme a Nova Senha</b>
        <vs-tooltip text="Confirme a nova senha" position="right">
          <font-awesome-icon
            style="color: #9b0e1c"
            :icon="['fas', 'question-circle']"
          />
        </vs-tooltip>
        <vs-col class="login-input">
          <span class="icon-password">
            <font-awesome-icon
              v-if="confirmNewPasswordType == 'password'"
              :icon="['fas', 'lock']"
            />
            <font-awesome-icon v-else :icon="['fas', 'unlock-alt']" />
          </span>
          <vs-input
            class="password"
            v-model="confirmNewPassword"
            :type="confirmNewPasswordType"
            placeholder="Confirme a nova senha"
          />

          <a
            v-if="confirmNewPasswordType == 'password'"
            class="show-password"
            @click="showConfirmNewPassword()"
          >
            <font-awesome-icon :icon="['fas', 'eye']" />
          </a>

          <a v-else class="show-password" @click="showConfirmNewPassword()">
            <font-awesome-icon :icon="['fas', 'eye-slash']" />
          </a>
        </vs-col>

        <vs-col class="security-password">
          <fieldset>
            <p class="password-criterion">
              A senha deve seguir os critérios abaixo:
            </p>
            <p :class="formateAmount()">
              ° Sua senha deve conter no mínimo 6 caracteres.
            </p>
            <p :class="formateEqualityPassword()">
              ° A nova senha e a atual devem ser diferentes.
            </p>
            <p :class="formateEquality()">
              ° A nova senha e a confirmação devem ser iguais.
            </p>
          </fieldset>
        </vs-col>

        <div
          v-if="errorPassword"
          style="
            color: black;
            margin-top: 10px;
            margin-bottom: 0 !important;
            width: 100%;
            text-align: center;
          "
          class="error-info"
        >
          <b class="error-title">
            <font-awesome-icon
              style="color: red; margin-right: 1.5px"
              :icon="['fas', 'times-circle']"
            />
            {{ errorPassword }}
          </b>
        </div>

        <div style="width: 100%">
          <vs-button
            class="button-login"
            :disabled="
              formateAmount() == 'reprovado' ||
              formateEqualityPassword() == 'reprovado' ||
              formateEquality() == 'reprovado'
            "
            @click="recuperarAcesso ? accessRegain() : passwordChange()"
          >
            <font-awesome-icon
              style="margin-right: 3.5px"
              :icon="['fas', 'check-circle']"
            />
            Confirmar Alteração de Senha
          </vs-button>
        </div>
      </vs-row>

      <vs-divider />

      <vs-row class="social">
        <a href="https://www.instagram.com/escolhaqi/" target="blank">
          <img
            class="social-media"
            src="../assets/instagram.png"
            alt="Instagram"
          />
        </a>
        <a
          href="https://www.youtube.com/c/QIFaculdadeEscolaT%C3%A9cnica"
          target="blank"
        >
          <img class="social-media" src="../assets/youtube.png" alt="YouTube" />
        </a>
        <a href="https://qi.edu.br/blog/" target="blank">
          <img class="social-media" src="../assets/blogger.png" alt="Blog" />
        </a>
        <a
          href="https://www.linkedin.com/school/qi-escolas-e-faculdades"
          target="blank"
        >
          <img
            class="social-media"
            src="../assets/linkedin.png"
            alt="LinkedIn"
          />
        </a>
        <a href="https://www.facebook.com/escolhaQI" target="blank">
          <img
            class="social-media"
            src="../assets/facebook.png"
            alt="Facebook"
          />
        </a>
        <a href="https://www.tiktok.com/@escolhaqi" target="blank">
          <img class="social-media" src="../assets/tiktok.png" alt="TikTok" />
        </a>
      </vs-row>

      <vs-popup title="Esqueceu sua Senha" :active.sync="popupAccess">
        <vs-card>
          <vs-row class="pd-t">
            <fieldset style="width: 100%">
              <legend>Usuário</legend>

              <vs-input
                v-model="raUser"
                placeholder="Digite seu Usuário"
                class="selectExampleDouble"
              />
            </fieldset>
          </vs-row>

          <vs-row
            v-if="errorRegainAccess"
            class="error-info"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              color: black;
              margin-bottom: 10px;
              width: 100% !important;
            "
          >
            <b class="error-title" style="display: flex; align-items: center">
              <font-awesome-icon
                style="color: red; margin-right: 5px"
                :icon="['fas', 'times-circle']"
              />
              {{ errorRegainAccess }}
            </b>
          </vs-row>

          <vs-row>
            <vs-col style="text-align: center">
              <vs-button
                color="danger"
                type="gradient"
                class="button"
                :disabled="!raUser"
                @click="resetPopupAccess()"
              >
                <font-awesome-icon
                  style="margin-right: 3.5px"
                  :icon="['fas', 'times-circle']"
                />
                Cancelar
              </vs-button>

              <vs-button
                color="success"
                type="gradient"
                class="button"
                :disabled="!raUser"
                @click="validateUser(raUser)"
              >
                <font-awesome-icon
                  style="margin-right: 3.5px"
                  :icon="['fas', 'check-circle']"
                />
                Validar
              </vs-button>
            </vs-col>
          </vs-row>
        </vs-card>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import LoginService from "../services/auth";
import Access from "../services/access";
import Email from "../services/email";
import Person from "../services/person";
import Password from "../services/password";
import { saveDataInStorage } from "../helpers/storage";
import { bus } from "../main";

/*(function (s, z, c, h, a, t) {
  s.webchat =
    s.webchat ||
    function () {
      (s.webchat.q = s.webchat.q || []).push(arguments);
    };
  (t = z.createElement(c)), (a = z.getElementsByTagName(c)[0]);
  t.async = 1;
  t.src = "https://qi.sz.chat/webchat/v2/webchat.js";
  a.parentNode.insertBefore(t, a);
  s.webchat("cid", "652f5814fdf2f0001818fde7");
  s.webchat("host", h);
})(window, document, "script", "https://qi.sz.chat");

window.addEventListener(
  "message",
  function (event) {
    if (event.origin !== "https://qi.sz.chat") return;
  },
  false
);*/

export default {
  name: "Login",
  data: () => ({
    user: "",
    password: "",
    passwordType: "password",
    newPasswordType: "password",
    confirmNewPasswordType: "password",
    temporaryToken: "password",
    validatePassword: false,
    changePassword: false,
    newPassword: null,
    passwordNew: false,
    confirmNewPassword: null,
    successValidate: false,
    randomPassword: null,
    newToken: false,
    infoEmail: null,
    isButtonToken: false,
    timer: 0,
    temporaryPassword: null,
    error: false,
    recuperarAcesso: false,
    errorTemporary: null,
    errorPassword: null,
    errorRegainAccess: null,
    popupAccess: false,
    nome: null,
    email: null,
    raUser: null,
  }),

  computed: {
    isPassword() {
      return this.passwordType == "password";
    },
    isNewPasswordType() {
      return this.newPasswordType == "password";
    },
    isConfirmNewPassword() {
      return this.confirmNewPasswordType == "password";
    },
    isConfirmToken() {
      return this.temporaryToken == "password";
    },
  },

  methods: {
    showPassword() {
      this.passwordType = this.isPassword ? "text" : "password";
    },

    showToken() {
      this.temporaryToken = this.isConfirmToken ? "text" : "password";
    },

    showNewPassword() {
      this.newPasswordType = this.isNewPasswordType ? "text" : "password";
    },

    showConfirmNewPassword() {
      this.confirmNewPasswordType = this.isConfirmNewPassword
        ? "text"
        : "password";
    },

    formateAmount() {
      if (this.newPassword && this.confirmNewPassword) {
        if (
          this.newPassword.length >= 6 &&
          this.confirmNewPassword.length >= 6
        ) {
          return "aprovado";
        } else {
          return "reprovado";
        }
      } else {
        return "reprovado";
      }
    },

    formateEqualityPassword() {
      if (this.recuperarAcesso) {
        if (this.newPassword) {
          if (this.temporaryPassword == this.newPassword) {
            return "reprovado";
          } else {
            return "aprovado";
          }
        } else {
          return "reprovado";
        }
      } else {
        if (this.newPassword) {
          if (
            this.temporaryPassword == this.newPassword ||
            this.password == this.newPassword
          ) {
            return "reprovado";
          } else {
            return "aprovado";
          }
        } else {
          return "reprovado";
        }
      }
    },

    formateEquality() {
      if (this.newPassword && this.confirmNewPassword) {
        if (this.newPassword == this.confirmNewPassword) {
          return "aprovado";
        } else {
          return "reprovado";
        }
      } else {
        return "reprovado";
      }
    },

    forgotPassword() {
      this.popupAccess = true;
      this.raUser = null;
      this.errorRegainAccess = null;
    },

    resetPopupAccess() {
      this.popupAccess = false;
    },

    validateUser(user) {
      this.infoEmail = null;
      this.errorRegainAccess = null;

      this.$vs.loading({ text: "Verificando Usuário 🎓" });
      new Person().regainAccess(user).then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];

        if (this.personalData[0] != "") {
          this.nome = this.personalData[0].NOME;
          this.email = this.personalData[0].EMAIL;

          new Email()
            .temporaryPassword({
              name: this.nome,
              email: this.email,
            })
            .then((res) => {
              this.randomPassword = this.decryptInfo(res.data.token);
              this.infoEmail = this.email;

              new Access()
                .access({
                  codPessoa: Number(this.personalData[0].CODIGO),
                  token: this.randomPassword,
                })
                .then(() => {
                  this.popupAccess = false;
                  this.validatePassword = true;
                  this.recuperarAcesso = true;
                  this.$vs.loading.close();
                })
                .catch((error) => {
                  const { response } = error;
                  this.error =
                    response?.data?.error || "Tente novamente mais tarde.";
                  this.$vs.loading.close();
                });
            })
            .catch((error) => {
              const { response } = error;
              this.error =
                response?.data?.error || "Tente novamente mais tarde.";
              this.$vs.loading.close();
            });
        } else {
          this.errorRegainAccess = "Usuário inválido!";
          this.$vs.loading.close();
        }
      });
    },

    accessRegain() {
      this.$vs.loading({ text: "Alterando Senha 🔐" });
      new LoginService()
        .accessPassword({
          codUser: this.raUser,
          newPassword: this.newPassword,
          newPasswordConfirmation: this.confirmNewPassword,
          changePasswordToken: this.randomPassword,
        })
        .then(() => {
          this.passwordNew = true;

          setTimeout(() => {
            this.validatePassword = false;
            this.changePassword = false;

            this.$vs.loading({ text: "Verificando Usuário 🎓" });
            new LoginService()
              .studentLogin({
                username: this.raUser,
                password: this.newPassword,
              })
              .then((res) => {
                saveDataInStorage(res.data);
                bus.$emit("login");
                this.$router.push("/home");
                this.$vs.loading.close();
              })
              .catch((error) => {
                const { response } = error;
                this.error =
                  response?.data?.error || "Tente novamente mais tarde.";
                this.$vs.loading.close();
              });
          }, 1100);
        })
        .catch((error) => {
          const { response } = error;
          this.errorPassword =
            response?.data?.error || "Erro ao trocar de senha.";
          this.$vs.loading.close();
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    maskEmail(email) {
      const [user, domain] = email.split("@");

      const maskUser = (user) => {
        const specialChars = [".", "_"];
        const segments = [];
        let currentSegment = "";

        for (let i = 0; i < user.length; i++) {
          if (specialChars.includes(user[i])) {
            if (currentSegment) {
              segments.push(currentSegment);
              currentSegment = "";
            }
            segments.push(user[i]);
          } else {
            currentSegment += user[i];
          }
        }
        if (currentSegment) segments.push(currentSegment);

        const maskedSegments = segments.map((segment) => {
          if (specialChars.includes(segment)) {
            return segment;
          } else if (segment.length <= 2) {
            return segment[0] + "*".repeat(segment.length - 1);
          } else if (segment.length <= 4) {
            return (
              segment.slice(0, 1) +
              "*".repeat(segment.length - 2) +
              segment.slice(-1)
            );
          } else {
            return (
              segment.slice(0, 3) +
              "*".repeat(segment.length - 3) +
              segment.slice(-2)
            );
          }
        });

        return maskedSegments.join("");
      };

      const maskedUser = maskUser(user);
      return maskedUser + "@" + domain;
    },

    validateToken(tempPassword) {
      this.errorTemporary = null;
      this.newPassword = null;
      this.confirmNewPassword = null;

      this.$vs.loading({ text: "Validando Token de Autenticação 🔒" });

      setTimeout(() => {
        this.$vs.loading.close();
        if (tempPassword == this.randomPassword) {
          this.successValidate = true;
          setTimeout(() => {
            this.changePassword = true;
            this.successValidate = false;
          }, 1100);
        } else {
          this.errorTemporary = "Token incorreto!";
        }
      }, 1500);
    },

    passwordChange() {
      this.errorPassword = null;

      this.$vs.loading({ text: "Alterando Senha 🔐" });
      new Password()
        .password({
          username: this.user,
          lastPassword: this.password,
          newPassword: this.newPassword,
          confirmationPassword: this.confirmNewPassword,
        })
        .then(() => {
          this.passwordNew = true;

          setTimeout(() => {
            this.validatePassword = false;
            this.changePassword = false;

            this.$vs.loading({ text: "Verificando Usuário 🎓" });
            new LoginService()
              .studentLogin({
                username: this.user,
                password: this.newPassword,
              })
              .then((res) => {
                saveDataInStorage(res.data);
                bus.$emit("login");
                this.$router.push("/home");
                this.$vs.loading.close();
              })
              .catch((error) => {
                const { response } = error;
                this.error =
                  response?.data?.error || "Tente novamente mais tarde.";
                this.$vs.loading.close();
              });
          }, 1100);
        })
        .catch((error) => {
          const { response } = error;
          this.errorPassword =
            response?.data?.error || "Erro ao trocar de senha.";
          this.$vs.loading.close();
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    decryptInfo(token) {
      const secretKey = "QI-System-@@";
      const simpleCrypto = new SimpleCrypto(secretKey);
      const returnToken = simpleCrypto.decrypt(token);
      return returnToken;
    },

    login() {
      this.error = null;
      this.recuperarAcesso = false;
      this.validatePassword = false;

      this.$vs.loading({ text: "Verificando Usuário 🎓" });
      new LoginService()
        .studentLogin({
          username: this.user,
          password: this.password,
        })
        .then((res) => {
          if (res.data.changepassword == true) {
            this.nome = res.data.userdata.NOME;
            this.email = res.data.userdata.EMAIL;

            new Email()
              .temporaryPassword({
                name: this.nome,
                email: this.email,
              })
              .then((res) => {
                this.validatePassword = true;

                this.randomPassword = this.decryptInfo(res.data.token);
                this.infoEmail = this.email;
                this.$vs.loading.close();
              })
              .catch((error) => {
                const { response } = error;
                this.error =
                  response?.data?.error ||
                  "Erro ao enviar o token de autenticação.";
                this.$vs.loading.close();
              });
          } else {
            saveDataInStorage(res.data);
            bus.$emit("login");
            this.$router.push("/home");
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          const { response } = error;
          this.error = response?.data?.error || "Tente novamente mais tarde.";
          this.$vs.loading.close();
        });
    },

    resendEmail() {
      this.newToken = false;
      this.$vs.loading({ text: "Reenviando Token 📩" });
      new Email()
        .temporaryPassword({
          name: this.nome,
          email: this.email,
        })
        .then((res) => {
          this.randomPassword = this.decryptInfo(res.data.token);

          new Access()
            .access({
              codPessoa: Number(this.personalData[0].CODIGO),
              token: this.randomPassword,
            })
            .then(() => {
              this.newToken = true;
              setTimeout(() => {
                this.newToken = false;
              }, 850);
              this.$vs.loading.close();

              this.isButtonToken = true;
              this.timer = 30;

              const interval = setInterval(() => {
                this.timer--;
                if (this.timer <= 0) {
                  clearInterval(interval);
                  this.isButtonToken = false;
                }
              }, 1000);
            })
            .catch((error) => {
              const { response } = error;
              this.error =
                response?.data?.error || "Tente novamente mais tarde.";
              this.$vs.loading.close();
            });
        })
        .catch((error) => {
          const { response } = error;
          this.error = response?.data?.error || "Tente novamente mais tarde.";
          this.$vs.loading.close();
        });
    },

    formatTime() {
      const seconds = this.timer.toString().padStart(2, "0");
      return `00:${seconds}`;
    },

    focusPasswordInput() {
      if (this.password == "") {
        this.$refs.password.focusInput();
      } else {
        this.login();
      }
    },
  },
};
</script>

<style scoped>
b {
  margin-right: 1.5%;
  font-size: 0.925em;
}

h1 {
  text-align: center;
  margin: 1em;
  color: #ffffff;
}

a {
  cursor: pointer;
}

h3 {
  margin: 0 auto;
  text-align: center;
}

.pd-t > div {
  width: 100%;
}

.pd-t .selectExampleDouble {
  width: 100%;
  margin-left: 0;
}

.qi-form {
  border-radius: 20px;
  box-shadow: 0px 0px 30px 0px #888888;
  margin: auto;
  width: 375px;
  background: #f1eeee;
  padding: 20px;
  color: #455a64;
}

.sucess {
  margin-bottom: 0px;
}

.qi-login {
  background: url(../assets/background.png) no-repeat top center fixed;
  box-shadow: rgba(0, 0, 0, 0.5) 15px 15px 85px -5px inset;
  background-size: cover;
  display: flex;
  height: 100vh;
}

.icon-contrast {
  margin: auto auto 5px auto;
}

.logo {
  margin-bottom: 5%;
}

.logo img {
  width: 60%;
}

.login-input {
  display: flex;
}

.label-password {
  margin-top: 2.75%;
}

.login-input a {
  margin: auto;
}

.security-password {
  margin-top: 18.5px;
}

.security-password p:nth-child(2) {
  margin-top: 1.25%;
}

.error-title {
  font-size: 0.985em;
}

.error-info,
.sucess-info {
  font-size: 13px;
}

.sucess-alert,
.error-alert {
  margin-top: 5px;
  font-size: 0.875em;
}

.user,
.currentPassword {
  width: 95%;
}

.password {
  width: 85%;
}

.password-criterion {
  font-size: 0.925em;
}

.aprovado,
.reprovado {
  font-size: 0.815em;
  font-weight: bold;
}

.aprovado {
  color: green;
}

.reprovado {
  color: red;
}

.icon-user,
.icon-password {
  margin: auto;
  padding: 1.35% 1.65% 1.35% 1.65%;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.button-login {
  background: #571515;
  border-radius: 20px;
  margin: 5% 2.5% auto 2.5%;
  width: 95%;
}

.show-password {
  padding: 1.5%;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 2.5px;
  color: #571515;
}

.button {
  border-radius: 20px;
  width: 45%;
  margin: 0 1.5%;
}

.error-login {
  margin-top: 5%;
  font-size: 0.95em;
  color: #8b0000;
  font-weight: bold;
}

.information_login {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.social {
  margin: auto;
  justify-content: space-around;
}

.social-media {
  width: 45px;
}

.social-media:hover {
  border-radius: 35px;
  border: 4.25px solid #5e0000;
}

.validacao {
  font-weight: bold;
  margin-top: 1.5%;
}

@media screen and (max-width: 500px) {
  .qi-login {
    background: linear-gradient(to right, #821618, #c92127);
    box-shadow: rgba(0, 0, 0, 0.35) 15px 15px 85px -5px inset;
  }

  .qi-form {
    box-shadow: 0px 0px 15px 0px #888888;
  }
}

@media screen and (max-width: 410px) {
  .qi-form {
    width: 90%;
    padding: 15px;
  }

  .button {
    width: 95%;
    margin: 1.5% 0;
  }

  .password-criterion {
    font-size: 0.85em;
  }

  .aprovado,
  .reprovado {
    font-size: 0.685em;
  }
}

@media screen and (max-width: 350px) {
  .password-criterion {
    font-size: 0.75em;
  }

  .aprovado,
  .reprovado {
    font-size: 0.615em;
  }
}

@media screen and (max-width: 330px) {
  .password-criterion {
    font-size: 0.735em;
  }
}
</style>
