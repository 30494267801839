import { https } from './apiIntranet';
export default class {
    constructor() {
        this.instance = https('portal/management')
    }    

    // PORTAL.10
    getFinancialStatement = () => {
        return this.instance ({ 
            method: 'GET',
            url: `/financialStatement`,
            timeout: 30000
        });
    }

    // PORTAL.04
    getClassesInProgress = () => {
        return this.instance ({
            method: 'GET',
            url: `/classesInProgress`,
            timeout: 30000
        });
    }

    // PORTAL.24
    getProtocolInProgress = () => {
        return this.instance ({
            method: 'GET',
            url: `/protocolInProgress`,
            timeout: 30000
        });
    }

    // PORTAL.25
    getProtocolCompleted = () => {
        return this.instance ({
            method: 'GET',
            url: `/protocolCompleted`,
            timeout: 30000
        });
    }

    // PORTAL.40
    getProtocolCertificate = () => {
        return this.instance ({
            method: 'GET',
            url: `/protocolCertificate`,
            timeout: 30000
        });
    }

    // PORTAL.41
    getAttachmentAttendance = (codColigada, codLocal, codAtendimento) => {
        return this.instance ({
            method: 'GET',
            url: `/attachmentAttendance/${codColigada}/${codLocal}/${codAtendimento}`,
            timeout: 30000
        });
    }

    // PORTAL.07
    getCoursesForTest = () => {
        return this.instance ({
            method: 'GET',
            url: `/coursesForTest`,
            timeout: 30000
        });
    }

    // PORTAL.26
    getCoursesForReport = () => {
        return this.instance ({
            method: 'GET',
            url: `/coursesForReport`,
            timeout: 30000
        });
    }

    // PORTAL.42
    getCoursesForCoefficient = () => {
        return this.instance ({
            method: 'GET',
            url: `/coursesForCoefficient`,
            timeout: 30000
        });
    }

    // PORTAL.20
    getGraduationCollation = () => {
        return this.instance ({
            method: 'GET',
            url: `/graduationCollation`,
            timeout: 30000
        });
    }

    // PORTAL.28
    getCoursesForHistoric = () => {
        return this.instance ({
            method: 'GET',
            url: `/coursesForHistoric`,
            timeout: 30000
        });
    }

    // PORTAL.08
    getIdTurmasDisc = () => {
        return this.instance ({ 
            method: 'GET',
            url: `/idTurmasDisc`,
            timeout: 30000
        });
    }

    // PORTAL.23
    getInstallmentsPayable = (idCourse) => {
        return this.instance ({ 
            method: 'GET',
            url: `/installmentsPayable/${idCourse}`,
            timeout: 30000
        });
    }

    // PORTAL.44
    getDisciplines = (idCourse) => {
        return this.instance ({ 
            method: 'GET',
            url: `/disciplines/${idCourse}`,
            timeout: 30000
        });
    }

    // PORTAL.14
    getDefaulting = () => {
        return this.instance ({ 
            method: 'GET',
            url: `/defaulting`,
            timeout: 30000
        });
    }

    // COM.WSMUNICIPIO
    getCities = (codEstado) => {
        return this.instance ({ 
            method: 'GET',
            url: `/cities/${codEstado}`,
            timeout: 30000
        });
    }

    // PORTAL.34
    getInvoicesFinance = (codColigada, idLan) => {
        return this.instance ({ 
            method: 'GET',
            url: `/invoice/${codColigada}/${idLan}`,
            timeout: 30000
        });
    }

    valueFinance = (data) => {
        return this.instance({
            method: 'POST',
            url: '/valueFinance',            
            data
        });
    }

    // PORTAL.36
    getCoursesForTechnician = () => {
        return this.instance ({
            method: 'GET',
            url: `/coursesForTechnician`,
            timeout: 30000
        });
    }

    // PORTAL.39
    getContract = () => {
        return this.instance ({
            method: 'GET',
            url: `/contract`,
            timeout: 30000
        });
    }

    updateCurriculumAnalysis = (data) => {
        return this.instance({
            method: 'POST',
            url: '/curriculum',            
            data
        });
    }
}